import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import StoryLeft from './StoryLeft'
import StoryRight from './StoryRight'

const Story = () => {
  return (
    <Box>
      <Grid container alignItems='stretch'>
        <Grid item xs={12} sm={6}>
          <StoryLeft />
        </Grid>
        <Grid item xs={12} sm={6}>
          <StoryRight />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Story
