import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
}

export default function MapModal({ open, setOpen }) {
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        sx={{
          mb: 3,
          textTransform: 'none',
          fontSize: 15,
        }}
      >
        South Goran, Khilgaon, Dhaka
      </Button>
      <BootstrapDialog
        fullWidth
        maxWidth='md'
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <BootstrapDialogTitle
          id='customized-dialog-title'
          onClose={handleClose}
        >
          Directions to RGB Jute
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <iframe
            title='map'
            width='100%'
            height='400px'
            id='gmap_canvas'
            src='https://maps.google.com/maps?q=khilgaon&t=k&z=13&ie=UTF8&iwloc=&output=embed'
            frameBorder='0'
            scrolling='yes'
            marginheight='0'
            marginwidth='0'
          ></iframe>
        </DialogContent>
      </BootstrapDialog>
    </div>
  )
}
