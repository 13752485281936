import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

const Mission = () => {
  return (
    <Container sx={{ py: 10 }}>
      <Grid container spacing={3} alignItems='stretch'>
        <Grid item xs={12} sm={4}>
          <Typography align='left' variant='h5' sx={{ fontWeight: 'bold' }}>
            Mission
          </Typography>
          <Divider
            sx={{
              my: 2,
              height: '2px',
              width: '100px',
              backgroundColor: 'secondary.main',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography
            // sx={{ ml: { xs: 0, sm: 5 } }}
            align='justify'
            variant='subtitle1'
          >
            Our mission is to promote ecofriendly, reusable, natural and locally
            sourced products by creating benchmarks for product quality, trained
            artisans, safe workspace, fastest production and best price in the
            market without compromising our customers’ satisfaction. Besides,
            ensuring all the parameter of quality of our goods by implementing
            Quality Control procedure.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Mission
