import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import bg from 'img/page-title-background.webp'

const bgStyle = {
  height: '60vh',
  backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), 
      url(${bg})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  backgroundAttachment: {
    xs: 'scroll',
    sm: 'fixed',
  },
}

const Hero = () => {
  return (
    <Box sx={bgStyle}>
      <Container>
        <Typography align="center" fontWeight="bold" color="#fff" variant="h3">
          CONTACT US
        </Typography>
      </Container>
    </Box>
  )
}

export default Hero
