import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

const TopText = () => {
  return (
    <Box p={5}>
      <Typography
        sx={{
          color: '#fff',
          fontWeight: 'bold',
          typography: { xs: 'h4', sm: 'h3' },
        }}
        align="left"
      >
        Sustainable Development
      </Typography>
      <Divider
        sx={{
          my: 2,
          height: '2px',
          width: '150px',

          backgroundColor: 'secondary.main',
        }}
      />
      <Typography
        sx={{ color: '#fff', textAlign: 'justify' }}
        align="left"
        variant="body1"
      >
        RGB Jute is a company that specializes in the production of
        high-quality, eco-friendly sustainable handmade products. We strive to
        provide our valued customers with a diverse range of products that meet
        the highest standards of quality and craftsmanship. We are committed to
        ensuring customer satisfaction and maintaining a safe and ethical
        workplace by implementing sustainable practices. Our goal is to provide
        superior quality handmade products while preserving the environment and
        the well-being of our employees and artisans.
      </Typography>

      <Box my={5} />
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={6}>
          <Typography
            sx={{ color: 'secondary.main' }}
            align="left"
            variant="h3"
          >
            10+
          </Typography>
          <Typography sx={{ color: '#fff' }} align="left" variant="h5">
            Product categories
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            sx={{ color: 'secondary.main' }}
            align="left"
            variant="h3"
          >
            7+
          </Typography>
          <Typography sx={{ color: '#fff' }} align="left" variant="h5">
            Country coverage
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            sx={{ color: 'secondary.main' }}
            align="left"
            variant="h3"
          >
            500+
          </Typography>
          <Typography sx={{ color: '#fff' }} align="left" variant="h5">
            Women artisans
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default TopText
