import emailjs from 'emailjs-com'

const sendEmail = ({
  name = 'Not given',
  companyName = 'Not given',
  email,
  user_email = 'thesaiff@gmail.com',
}) => {
  emailjs
    .send(
      'serviceID',
      'templateID',
      {
        to_name: 'Hasin',
        from_name: name,

        reply_to: user_email,
        message: `
        Catalog Request,

        Company Name : ${companyName}
        Email: ${email}
        `,
      },
      'user_hanCZLRNAvSiZbGiC79ZG'
    )
    .then(
      (result) => {
        console.log(result.text)
      },
      (error) => {
        console.log(error.text)
      }
    )
}

export default sendEmail
