import Box from '@mui/material/Box'
import Categories from 'components/Categories'
import Footer from 'components/Footer'

const Category = () => {
  return (
    <Box>
      <Categories />
      <Footer />
    </Box>
  )
}

export default Category
