import Box from '@mui/material/Box'

import Hero from './Hero'
import Cards from './Cards/Cards'

const Categories = () => {
  return (
    <Box>
      <Hero />

      <Cards />
    </Box>
  )
}

export default Categories
