import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Box from "@mui/system/Box"
import bg from "img/new/box2.webp"
import { Link } from "react-router-dom"

const Left = () => {
  return (
    <Box
      sx={{
        height: { xs: "60vh", sm: "100vh" },
        width: "100%",
        position: "relative",
        overflow: "hidden",
        "&:hover": {
          "& .bg": {
            transform: "scale(1.1)",
          },
          "& .overlay": {
            opacity: "100%",
            p: { xs: 5, sm: 8 },
          },
        },
      }}
    >
      <Box
        className="bg"
        sx={{
          transition: "all 2s ease-in-out",
          position: "absolute",
          height: "100%",
          width: "100%",
          top: 0,
          left: 0,
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />

      <Box
        className="overlay"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          opacity: 0,
          width: "100%",
          height: "100%",
          bgcolor: "rgba(178, 144, 99, 0.95);",
          transition: "all 0.7s ease-in-out",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          p: { xs: 2, sm: 5 },
        }}
      >
        <Typography
          variant="h3"
          color="#fff"
          sx={{ my: 2, fontSize: { xs: 40, sm: 45 } }}
        >
          Welcome to RGB Jute
        </Typography>
        <Button
          to="/about"
          component={Link}
          sx={{
            color: "#fff",
            ml: -1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          Learn More
          <Box
            sx={{ bgcolor: "primary.main", height: 2, width: "100%", my: 0.2 }}
          />
        </Button>
      </Box>
    </Box>
  )
}

export default Left
