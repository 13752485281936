// Import Swiper React components

// Import Swiper styles
import "swiper/css"
import "swiper/css/effect-fade"
import "swiper/css/pagination"

// import Swiper core and required modules
import Box from "@mui/material/Box"
import SwiperCore, { Autoplay, EffectFade } from "swiper"
import Single2 from "./Single2"

// install Swiper modules
SwiperCore.use([Autoplay, EffectFade])

const Hero = () => {
  return (
    <Box>
      {/* <Swiper
        autoplay={{ delay: 2000, disableOnInteraction: false }}
        speed={1200}
        loop
        grabCursor={true}
        effect={'fade'}
        className='mySwiper'
      >
        <SwiperSlide>
          <Single />
        </SwiperSlide>
        <SwiperSlide> */}
      <Single2 />
      {/* </SwiperSlide>
      </Swiper> */}
    </Box>
  )
}

export default Hero
