const facts = [
  {
    id: 1,
    title: "Sustainable Materials",
    description:
      "We use 100% sustainable raw materials like jute, sea grass, which are locally sourced, biodegradable and have a minimal environmental impact supporting a greener planet.",
  },
  {
    id: 2,
    title: "Fully Compliance",
    description:
      "RGB Jute proudly holds BSCI and SEDEX 4 Pillar certifications, demonstrating our commitment to ethical business practices, corporate social responsibility and sustainability.",
  },
  {
    id: 3,
    title: "Production Capacity",
    description:
      "With our large production capacity, RGB Jute can efficiently fulfill bulk orders while maintaining the highest standards of quality, craftsmanship ensuring on time delivery.",
  },
  {
    id: 4,
    title: "Customized Design",
    description:
      "Since we are the manufacturer, we offer our valued customer to choose any design, color, pattern, sizes, hangtag, and logo to customize products for their brand exclusively.",
  },
  {
    id: 5,
    title: "Flexibility of MOQ",
    description:
      "We are open to both Least Container Load (LCL) and Full Container Load (FCL) shipping option as per our customer's convenience. Also, we are flexible with order quantity.",
  },
  {
    id: 6,
    title: "Swift Communication",
    description:
      "We are committed to providing the fastest response ensuring that all inquiries, orders, samples request and concerns are addressed promptly for smooth collaboration.",
  },
]

export default facts
