import Box from '@mui/material/Box'
import facts from 'img/factsbg.jpg'
import FactBox from './FactBox'

const Facts = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${facts})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: {
          xs: 'scroll',
          sm: 'fixed',
        },
      }}
    >
      <FactBox />
    </Box>
  )
}

export default Facts
