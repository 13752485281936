import { Typography, useScrollTrigger } from "@mui/material"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Toolbar from "@mui/material/Toolbar"

import Menu from "components/Menu"
import { Link } from "react-router-dom"

export default function NavbarM({ window }) {
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  })
  return (
    <Container>
      {/* <Box sx={{ flexGrow: 1 }}> */}
      <AppBar
        elevation={0}
        sx={{
          background: trigger ? "#212121" : "transparent",

          transition: "all 0.5s ease-in-out",
        }}
      >
        <Toolbar sx={{ minHeight: "50px !important" }}>
          <Box
            component={Link}
            to="/"
            sx={{
              height: "100%",
              flexGrow: 1,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "semibold",
                color: "#fff",
                textTransform: "uppercase",
                // letterSpacing: "1px",
              }}
            >
              RGB
              <Box
                sx={{
                  width: "5px",
                  display: "inline-block",
                }}
              />{" "}
              JUTE
            </Typography>
          </Box>
          <Box>
            <Menu />
          </Box>
        </Toolbar>
      </AppBar>
      {/* </Box> */}
    </Container>
  )
}
