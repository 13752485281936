import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Box from "@mui/system/Box"
import bg from "img/new/box2.webp"
import { Link } from "react-router-dom"

const LeftSm = () => {
  return (
    <Box
      sx={{
        height: "60vh",
        width: "100%",

        transition: "all 2s ease-in-out",

        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.8)), 
          url(${bg})`,

        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        px: 5,
        py: 2,
      }}
    >
      <Typography variant="h4" color="#fff" sx={{ fontSize: 35 }}>
        The RGB Way
      </Typography>
      <Button
        to="/about"
        component={Link}
        sx={{
          color: "#fff",
          ml: -1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        Learn More
        <Box
          sx={{ bgcolor: "secondary.main", height: 2, width: "100%", my: 0.2 }}
        />
      </Button>
    </Box>
  )
}

export default LeftSm
