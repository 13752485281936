import { useEffect, useState } from 'react'
import { base } from 'utils/constants'

const useAirtableGet = (url) => {
  const [data, setData] = useState([])

  useEffect(() => {
    base(url)
      .select({ view: 'Grid view' })
      .eachPage((records, fetchNextPage) => {
        setData(records)
        fetchNextPage()
      })
  }, [url])

  return { data }
}

export default useAirtableGet
