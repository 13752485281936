import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import Typography from "@mui/material/Typography"
import { useState } from "react"

import CloseIcon from "@mui/icons-material/Close"
import FacebookIcon from "@mui/icons-material/Facebook"
import InstagramIcon from "@mui/icons-material/Instagram"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import MenuIcon from "@mui/icons-material/Menu"
import SearchIcon from "@mui/icons-material/Search"
import WhatsAppIcon from "@mui/icons-material/WhatsApp"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import TextField from "@mui/material/TextField"
import amfori from "img/amfori.svg"

import { Link } from "react-router-dom"
import { data } from "./data"

export default function Menu() {
  const [state, setState] = useState({
    right: false,
  })

  // Check Nav VPN
  // const bd = true

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const list = (anchor) => (
    <Box
      p={2}
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 350,
        height: "100%",
        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List
        sx={{
          flex: 1,
        }}
      >
        <ListItem
          secondaryAction={
            <IconButton
              onClick={toggleDrawer(anchor, false)}
              edge="end"
              aria-label="delete"
            >
              <CloseIcon color="primary" fontSize="large" />
            </IconButton>
          }
        >
          <Typography variant="h5">Menu</Typography>
        </ListItem>

        <ListItem
          component={Link}
          to="search"
          onClick={toggleDrawer(anchor, false)}
        >
          <TextField
            sx={{ mt: 2 }}
            size="small"
            fullWidth
            id="p1"
            label="Search Products..."
            variant="outlined"
            InputProps={{ endAdornment: <SearchIcon /> }}
          />
        </ListItem>

        <Box my={2} />
        {data.map((item) => (
          <ListItem
            component={Link}
            to={item.to}
            key={item.id}
            onClick={toggleDrawer(anchor, false)}
            button
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.name} />
          </ListItem>
        ))}
      </List>

      <Box
        sx={{
          mt: 5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <ListItem onClick={toggleDrawer(anchor, false)} button>
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            component="a"
            href="https://www.facebook.com/rgbjute/"
            target="blank"
            sx={{ borderRadius: 0, mr: 0.5 }}
          >
            <FacebookIcon />
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            component="a"
            href="https://www.instagram.com/rgbjute/"
            target="blank"
            sx={{ borderRadius: 0, mr: 0.5 }}
          >
            <InstagramIcon />
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            component="a"
            href="https://www.linkedin.com/company/rgbjute"
            target="blank"
            sx={{ borderRadius: 0, mr: 0.5 }}
          >
            <LinkedInIcon />
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            component="a"
            href="https://wa.me/+8801739700228"
            target="blank"
            sx={{ borderRadius: 0 }}
          >
            <WhatsAppIcon />
          </Button>
        </ListItem>

        <ListItem sx={{ mt: 1 }} onClick={toggleDrawer(anchor, false)} button>
          <Typography>
            All rights reserved by RGB Jute | &copy; {new Date().getFullYear()}
          </Typography>
        </ListItem>
        <Box
          onClick={toggleDrawer(anchor, false)}
          sx={{
            "& img": {
              display: "block",
              height: "auto",
              width: "100%",
              px: 2,
              zIndex: 1,
            },
          }}
        >
          <img src={amfori} alt="amfori" />
        </Box>
      </Box>
    </Box>
  )

  return (
    <Box>
      {["right"].map((anchor) => (
        <Box key={anchor}>
          <IconButton
            size="small"
            sx={{ color: "#fff", p: 0 }}
            onClick={toggleDrawer(anchor, true)}
          >
            <MenuIcon sx={{ fontSize: 30 }} />
          </IconButton>

          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </Box>
      ))}
    </Box>
  )
}
