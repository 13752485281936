import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

const GlobalReach = () => {
  return (
    <Container sx={{ py: 10 }}>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} sm={4}>
          <Typography align="left" variant="h5" sx={{ fontWeight: 'bold' }}>
            Global Reach
          </Typography>
          <Divider
            sx={{
              my: 2,
              height: '2px',
              width: '100px',
              backgroundColor: 'secondary.main',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography
            // sx={{ ml: { xs: 0, sm: 5 } }}
            align="justify"
            variant="subtitle1"
          >
            RGB Jute has a strong presence in the international market,
            exporting high-quality home decor, garden decor, kid’s products to
            countries such as the USA, Canada, Germany, Denmark, Netherlands,
            and Norway. With a focus on quality, sustainability, traditional
            craftsmanship and customer satisfaction, we are continually
            exploring new opportunities to expand our reach and bring our unique
            products to new markets around the world.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

export default GlobalReach
