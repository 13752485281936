import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import bg from 'img/productBG.webp'
import { useState } from 'react'
import Modal from './Modal'
import ModalPolicy from './ModalPolicy'

const Hero = () => {
  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)
  return (
    <Box
      sx={{
        height: '60vh',
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 1)), 
            url(${bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Container>
        <Typography align="center" color="#fff" variant="h3">
          Product Categories
        </Typography>
      </Container>
      <Grid
        sx={{ position: 'absolute', bottom: 0 }}
        container
        alignItems="stretch"
      >
        <Grid item xs={12} sm={6} sx={{ p: 3 }}>
          <Modal open={open} setOpen={setOpen} />
          <Button
            onClick={() => setOpen(true)}
            variant="outlined"
            fullWidth
            color="secondary"
          >
            Request a Catalog
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ p: 3 }}>
          <ModalPolicy open={open2} setOpen={setOpen2} />
          <Button
            onClick={() => setOpen2(true)}
            variant="outlined"
            fullWidth
            color="secondary"
          >
            Order Policy
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Hero
