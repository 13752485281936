import { useMediaQuery } from "@mui/material"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Left from "./Left"
import LeftSm from "./LeftSm"
import Right from "./Right"
import RightSm from "./RightSm"

const Welcome = () => {
  const isSmall = useMediaQuery("(max-width:600px)")

  return (
    <Box>
      <Grid container sx={{ flexWrap: "wrap-reverse" }}>
        <Grid item xs={12} sm={12} lg={6}>
          {isSmall ? <LeftSm /> : <Left />}
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
          {isSmall ? <RightSm /> : <Right />}
        </Grid>
      </Grid>
    </Box>
  )
}

export default Welcome
