import { useScrollTrigger } from '@mui/material'
import Box from '@mui/material/Box'

const MouseScroll = (props) => {
  const trigger = useScrollTrigger({
    target: props.window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  })
  return (
    <Box
      onClick={(e) => {
        e.stopPropagation()
        window.scrollTo({
          top: window.innerHeight,
          behavior: 'smooth',
        })
      }}
      sx={{
        opacity: trigger ? 0 : 100,
        transition: 'opacity 0.5s ease-in-out',
        cursor: trigger ? 'default' : 'pointer',
        '&:hover': {
          '& .mouse': {
            transform: 'scale(1.3) !important',
          },
          '& .mouse-in': {
            transform: 'scale(1.3) !important',
          },
        },
      }}
      id="mouse-scroll"
    >
      <div className="mouse">
        <div className="mouse-in"></div>
      </div>
      <div>
        <span className="down-arrow-1"></span>
        <span className="down-arrow-2"></span>
        <span className="down-arrow-3"></span>
      </div>
    </Box>
  )
}

export default MouseScroll
