import AnchorIcon from '@mui/icons-material/Anchor'
const data = [
  {
    id: 1,
    title: 'Ecofriendly',
    desc: 'RGB Jute has been trying to maximize recycling concepts, for which we make our move to promote biodegradable, eco-friendly products into the market gracefully so we can contribute to nature.',
    logo: <AnchorIcon sx={{ color: '#fff', fontSize: 50 }} />,
    bg: '#212121',
  },
  {
    id: 2,
    title: 'Handmade',
    desc: 'Every good of our company are completely handmade by our woman artisan. Each of the products are unique pieces as they made by individual artisan. Our motive is to introduced them globally.',
    logo: <AnchorIcon sx={{ color: '#fff', fontSize: 50 }} />,
    bg: '#424242',
  },
  {
    id: 3,
    title: 'Fair Wages',
    desc: 'We strictly maintain fair wages in term of Bangladesh labor standard and ensure safe working environment for our talented artisan. We are firmly determining to uphold the right of our artisans.',
    logo: <AnchorIcon sx={{ color: '#fff', fontSize: 50 }} />,
    bg: '#616161',
  },
  {
    id: 4,
    title: 'Social Impact',
    desc: 'We are creating an opportunity for deprived, underprivileged but talented woman, who are also eager to change their fate and want to be economically independent by working with our company.',
    logo: <AnchorIcon sx={{ color: '#fff', fontSize: 50 }} />,
    bg: '#757575',
  },
]

export default data
