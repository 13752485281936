import { Grid } from "@mui/material"
import { Box } from "@mui/system"
import SingleCard from "./SingleCard"

// import Animate from '../Animate'

const Cards = ({ items }) => {
  return (
    <Box py={5}>
      <Grid container spacing={5}>
        {items.map((item) => (
          <Grid key={item.id} item xs={12} sm={6} md={3}>
            {/* <Animate anim='zoom-in-up'> */}
            <SingleCard item={item} />
            {/* </Animate> */}
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default Cards
