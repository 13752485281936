// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

// import Swiper core and required modules
import SwiperCore, { Pagination } from 'swiper'

import pic1 from 'img/aboutC1.webp'
import pic2 from 'img/aboutC2.webp'
import pic3 from 'img/aboutC3.webp'
// import pic4 from 'img/aboutC4.webp'
// import pic5 from 'img/aboutC5.webp'
// import pic6 from 'img/aboutC6.webp'
// import pic7 from 'img/aboutC7.webp'
import BoxAnim from './BoxAnim'
import Box from '@mui/material/Box'

// install Swiper modules
SwiperCore.use([Pagination])

export default function ThreePicsDesktop() {
  return (
    <Swiper
      // slidesPerView={1}
      spaceBetween={0}
      // autoplay={{ delay: 2000, disableOnInteraction: false }}
      speed={1200}
      loop
      pagination={{
        clickable: true,
      }}
      breakpoints={{
        640: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
      }}
      // className='mySwiper'
    >
      <SwiperSlide>
        <Box pb={6}>
          <BoxAnim img={pic1} text='Create' />
        </Box>
      </SwiperSlide>
      <SwiperSlide>
        <Box pb={6}>
          <BoxAnim img={pic2} text='Enhance' />
        </Box>
      </SwiperSlide>
      <SwiperSlide>
        <Box pb={6}>
          <BoxAnim img={pic3} text='Sustain' />
        </Box>
      </SwiperSlide>
      {/* <SwiperSlide>
        <Box pb={6}>
          <BoxAnim img={pic4} text='Architecture' />
        </Box>
      </SwiperSlide>
      <SwiperSlide>
        <Box pb={6}>
          <BoxAnim img={pic5} text='Architecture' />
        </Box>
      </SwiperSlide>
      <SwiperSlide>
        <Box pb={6}>
          <BoxAnim img={pic6} text='Architecture' />
        </Box>
      </SwiperSlide>
      <SwiperSlide>
        <Box pb={6}>
          <BoxAnim img={pic7} text='Architecture' />
        </Box>
      </SwiperSlide> */}
    </Swiper>
  )
}
