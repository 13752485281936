import Box from '@mui/material/Box'
import bg from 'img/aboutBox.webp'

const StoryLeft = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${bg})`,
        height: { xs: '50vh', sm: '100%' },
        width: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    />
  )
}

export default StoryLeft
