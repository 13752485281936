import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import bg from 'img/page-title-background.webp'

const Hero = ({ name }) => {
  return (
    <Box
      sx={{
        height: '60vh',
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), 
            url(${bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Container>
        <Typography align='center' color='#fff' variant='h3'>
          {name}
        </Typography>
      </Container>
    </Box>
  )
}

export default Hero
