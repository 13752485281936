import Box from "@mui/material/Box"
import bg from "img/Sustainability/hero.jpg"
import bgM from "img/Sustainability/heroM.jpg"

const Hero = () => {
  return (
    <Box
      sx={{
        height: { xs: "45vh", sm: "100vh" },
        width: "100%",
        bgcolor: "#0A131B",
        backgroundImage: { xs: `url(${bgM})`, sm: `url(${bg})` },
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    ></Box>
  )
}

export default Hero
