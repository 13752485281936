import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Call from './Call'
import Email from './Email'
import Location from './Location'

const Cards = () => {
  return (
    <Container
      sx={{
        mt: 2,
        minHeight: '40vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid container alignItems="stretch">
        <Grid item xs={12} sm={4}>
          <Call />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Email />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Location />
        </Grid>
      </Grid>
    </Container>
  )
}

export default Cards
