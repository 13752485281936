import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
// import ChairOutlinedIcon from '@mui/icons-material/ChairOutlined'
import BakeryDiningIcon from "@mui/icons-material/BakeryDining"

const Single = ({ title, description }) => {
  return (
    <Box
      sx={{
        width: "100%",
        p: 2,
        height: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        background: "rgba( 255, 255, 255, 0.25)",
        boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
        backdropFilter: "blur( 4px )",
        border: "1px solid rgba( 255, 255, 255, 0.18 )",
        borderRadius: 1,
      }}
    >
      <BakeryDiningIcon sx={{ fontSize: 70 }} />

      <Typography align="center" variant="h5" sx={{ fontWeight: "bold" }}>
        {title}
      </Typography>
      <br />
      <Typography align="center" variant="subtitle2">
        {description}
      </Typography>
    </Box>
  )
}

//

export default Single
