import { Box } from '@mui/material'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import { default as Typography } from '@mui/material/Typography'

const policies = [
  {
    title: 'No Child Labor',
    description:
      'We do not employ any child labor in our production processes. We believe that every child has the right to education and a childhood free from exploitation, and we work to ensure that our products are produced under fair and humane conditions.',
  },
  {
    title: 'Fair Wages',
    description:
      'We believe that every artisan deserves a fair wage for their labor. We ensure that all our employees, including our artisans and craftsmen, are paid a fair wage that is commensurate with their skills and experience.',
  },
  {
    title: 'Maximizing the Benefits of Artisans',
    description:
      'We recognize the importance of preserving traditional skills and craftsmanship, and we work to ensure that our artisans receive the maximum benefits from their work. We collaborate with our artisans to create high-quality products that showcase their skills, and we provide them with training and resources to improve their craft.',
  },
  {
    title: 'Women in Work',
    description:
      'We believe that empowering women is essential for creating a sustainable and equitable world. We work to ensure that women have equal access to employment opportunities and that they are treated with respect and dignity in the workplace.',
  },
  {
    title: 'Gender Equity',
    description:
      'We are committed to promoting gender equity in all aspects of our business. We ensure that all our employees, regardless of gender, are treated with fairness and respect, and we work to eliminate any gender-based discrimination in our workplace.',
  },
  {
    title: 'Respect for the Environment',
    description:
      'We believe that our business should not come at the expense of the environment. We strive to minimize our environmental impact by using eco-friendly production methods, reducing waste and conserving natural resources.',
  },
  {
    title: 'Transparent Accountability',
    description:
      'We believe in being accountable for our actions and being transparent about our business practices. We regularly monitor and evaluate our operations to ensure that we are meeting our ethical and sustainability goals. We also welcome feedback from our customers, employees, and stakeholders to help us improve our practices.',
  },
  {
    title: 'Capacity Building',
    description:
      'We believe in investing in the capacity building of our employees, artisans, and communities. We provide training and resources to help them develop new skills and improve their productivity, which contributes to their long-term growth and development.',
  },
  {
    title: 'Good Working Conditions',
    description:
      'We believe that a safe and healthy workplace is essential for our employees well-being and productivity. We ensure that our employees work in good working conditions, including adequate lighting, ventilation, and safety measures.',
  },
]

const FairBusiness = () => {
  return (
    <Container sx={{ py: 10 }}>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} sm={4}>
          <Typography align="left" variant="h5" sx={{ fontWeight: 'bold' }}>
            Fair Business Practices
          </Typography>
          <Divider
            sx={{
              my: 2,
              height: '2px',
              width: '100px',
              backgroundColor: 'secondary.main',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography
            // sx={{ ml: { xs: 0, sm: 5 } }}
            align="justify"
            variant="subtitle1"
          >
            At RGB Jute, we are committed to conducting our business in a fair,
            responsible, and sustainable manner. We believe that our success as
            a company is inseparable from our commitment to ethical practices
            and respect for the people and environment around us. Here are some
            of the ways in which we strive to ensure fair business practices:
          </Typography>
          {policies.map((policy, index) => (
            <Box
              component="ul"
              sx={{
                my: 2,
                textAlign: 'justify',

                '& p': {
                  fontWeight: 'bold',
                  display: 'inline',
                },
              }}
            >
              <li key={index}>
                <p>{policy.title}</p> : {policy.description}
              </li>
            </Box>
          ))}
          <Typography
            // sx={{ ml: { xs: 0, sm: 5 } }}
            align="justify"
            variant="subtitle1"
          >
            At RGB Jute, we believe that conducting our business in a fair and
            ethical manner is not only the right thing to do, but it also
            contributes to our long-term success as a company. We are committed
            to continuous improvement in our business practices and to making a
            positive impact on the people and environment around us.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

export default FairBusiness
