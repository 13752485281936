import Box from '@mui/material/Box'
import Footer from 'components/Footer'
import Products from 'components/Products'
import { useParams } from 'react-router-dom'

const Product = () => {
  let { id } = useParams()

  return (
    <Box>
      <Products name={id} />
      <Footer />
    </Box>
  )
}

export default Product
