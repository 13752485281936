import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import CallIcon from '@mui/icons-material/Call'

const Call = () => {
  return (
    <Box
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CallIcon sx={{ fontSize: 45, color: 'primary.main', mb: 4 }} />
      <Typography
        variant="h5"
        fontWeight="bold"
        color="primary.main"
        sx={{ mb: 3 }}
      >
        Phone
      </Typography>

      <Box
        sx={{
          bgcolor: 'secondary.main',
          height: 2,
          width: { xs: '50%', sm: '40%' },
          my: 1,
        }}
      />
      {/* <Typography
        variant='subtitle1'
        color='gray'
        align='center'
        sx={{ mb: 3 }}
      >
        A wonderful serenity has taken possession of my entire soul, like these
        sweet mornings.
      </Typography> */}
      <Button
        sx={{
          mb: 3,

          textTransform: 'none',
          fontSize: 15,
        }}
        component="a"
        href="tel:+8801739700228"
      >
        +8801739700228
      </Button>
    </Box>
  )
}

export default Call
