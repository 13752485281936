import Box from '@mui/material/Box'
import Footer from 'components/Footer'
import Hero from './Hero'
import Cards from './Cards'
import Form from './Form'

const ContactGrid = () => {
  return (
    <Box>
      <Hero />
      <Cards />
      <Form />

      <Footer />
    </Box>
  )
}

export default ContactGrid
