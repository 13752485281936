import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Footer from 'components/Footer'
import left from 'img/bgtest.jpg'
import TextTheme from 'TextTheme'
import Table from './Table'

const ProfileIndex = () => {
  return (
    <Box>
      <Box
        sx={{
          p: { xs: 10, sm: 15 },
          bgcolor: 'primary.main',

          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.4)), url(${left})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Typography
          color="#fff"
          sx={{ fontWeight: 'bold' }}
          variant="h2"
          align="center"
        >
          Company Profile
        </Typography>
      </Box>
      <Container sx={{ my: { xs: 2, sm: 3 } }} maxWidth="xxl">
        <TextTheme>
          <Table />
        </TextTheme>
      </Container>

      <Footer />
    </Box>
  )
}

export default ProfileIndex
