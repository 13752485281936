import { Box, Container, Grid, Typography } from "@mui/material"
import compliance from "img/Sustainability/compliance.jpg"

const Compliance = () => {
  return (
    <Box
      sx={{
        bgcolor: "#fafafa",
        py: { xs: 2, sm: 5 },
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" align="justify">
              RGB Jute strictly adheres to all compliance laws and regulations
              in our manufacturing processes, following international ILO laws
              and local regulations to maintain the highest standards of
              ethical, social, and environmental responsibility. Our commitment
              to these legal frameworks enhances the credibility of our
              practices, ensuring transparency, fair labor conditions, and
              sustainability in every aspect of our operations. By meeting these
              globally recognized standards, we provide our partners with the
              assurance that our products are not only of high quality but also
              ethically and responsibly produced. We believe that responsible
              compliance is essential for building trust with our stakeholders
              and creating a positive impact on the communities we serve.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              "& img": {
                width: "100%",
                height: "auto",
              },
            }}
          >
            <img src={compliance} alt="compliance" />
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Compliance
