import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material"
import { Box } from "@mui/system"
import { Link } from "react-router-dom"

export default function SingleCard({ item }) {
  const { id, fields } = item

  return (
    <Card
      sx={{
        borderRadius: 1,
        bgcolor: "lightgray",
        transition: "0.2s",
        "&:hover": {
          transform: "scale(1.05)",
          boxShadow: 2,
        },
      }}
      align="left"
    >
      <CardActionArea component={Link} to={`${id}`}>
        <Box
          sx={{
            position: "relative",
            overflow: "hidden",
            "&:hover": {
              "& .cardOverlay": {
                opacity: 0.8,
                transform: "translateY(0%)",
              },
              "& .cardText": {
                opacity: 1,
                transform: "translateY(0%)",
              },
            },
          }}
        >
          <Box
            className="cardOverlay"
            sx={{
              transform: "translateY(100%)",
              transition: "0.5s",
              position: "absolute",
              width: "100%",
              height: "100%",
              opacity: 0,
              bgcolor: "primary.main",
            }}
          />
          <Box
            className="cardText"
            sx={{
              transition: "0.7s",
              transform: "translateY(20%)",
              position: "absolute",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              opacity: 0,
            }}
          >
            <Button variant="contained" color="secondary">
              View Details
            </Button>
          </Box>
          <CardMedia
            sx={{ paddingTop: "100%", width: "100%", objectFit: "cover" }}
            image={fields?.image[0]?.url}
            title="Card img"
          />
        </Box>
        <CardContent>
          <Typography variant="h5" color="black">
            {fields?.name}
          </Typography>
          {/* <Typography variant='body1' color='black'>
            {code}
          </Typography> */}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
