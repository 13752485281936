import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const SingleBox = ({ title, desc, logo, bg }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        p: 7,
        minHeight: '60vh',
      }}
    >
      {logo}
      <br />
      <br />
      <Typography
        sx={{ mb: 4, color: '#fff', fontWeight: 'bold' }}
        align='center'
        variant='h5'
      >
        {title}
      </Typography>
      <Typography sx={{ color: '#fff' }} align='center' variant='body1'>
        {desc}
      </Typography>
    </Box>
  )
}

export default SingleBox
