import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import { styled } from "@mui/material/styles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

export default function BasicTable() {
  return (
    <TableContainer component={Paper} sx={{ background: "#212121" }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow hover>
            <StyledTableCell
              sx={{
                // width: { xs: '40%', sm: '30%' },
                fontSize: { xs: 18, sm: 25 },
                borderRight: "1px solid rgba(81, 81, 81, 1)",
              }}
            >
              Company Name
            </StyledTableCell>
            <StyledTableCell align="left" sx={{ fontSize: { xs: 18, sm: 25 } }}>
              M/S RGB Jute
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow hover>
            <TableCell
              sx={{
                borderRight: "1px solid rgba(81, 81, 81, 1)",
                fontSize: { xs: 15, sm: 18 },
              }}
              component="th"
              scope="row"
            >
              Represented by
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontSize: { xs: 15, sm: 18 },
              }}
            >
              <li>Hasin Ishraque Sajid (Co-founder)</li>
              <li>Mezbah Rabbi (Co-founder)</li>
            </TableCell>
          </TableRow>

          <TableRow hover>
            <TableCell
              sx={{
                borderRight: "1px solid rgba(81, 81, 81, 1)",
                fontSize: { xs: 15, sm: 18 },
              }}
              component="th"
              scope="row"
            >
              Registered Address
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontSize: { xs: 15, sm: 18 },
              }}
            >
              163/165, Shantipur, South Goran,
              <br />
              Khilgaon, Dhaka-1219, Bangladesh.
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell
              sx={{
                borderRight: "1px solid rgba(81, 81, 81, 1)",
                fontSize: { xs: 15, sm: 18 },
              }}
              component="th"
              scope="row"
            >
              Communication
            </TableCell>
            <TableCell align="left">
              <Button
                sx={{ pl: 0, fontSize: { xs: 15, sm: 18 } }}
                color="secondary"
                component="a"
                href="tel:+8801739700228"
              >
                +8801739700228
              </Button>
              <br />
              <Button
                sx={{ pl: 0, fontSize: { xs: 15, sm: 18 } }}
                color="secondary"
                component="a"
                href="tel:+8801521332480"
              >
                +8801521332480
              </Button>
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell
              sx={{
                borderRight: "1px solid rgba(81, 81, 81, 1)",
                fontSize: { xs: 15, sm: 18 },
              }}
              component="th"
              scope="row"
            >
              Internet Reference
            </TableCell>
            <TableCell align="left">
              <Button
                sx={{
                  pl: 0,
                  textTransform: "none",
                  fontSize: { xs: 15, sm: 18 },
                }}
                color="secondary"
                component="a"
                target="_blank"
                href="www.rgbjute.com"
              >
                www.rgbjute.com
              </Button>
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell
              sx={{
                borderRight: "1px solid rgba(81, 81, 81, 1)",
                fontSize: { xs: 15, sm: 18 },
              }}
              component="th"
              scope="row"
            >
              Contact (Email)
            </TableCell>
            <TableCell align="left">
              <Button
                sx={{
                  pl: 0,
                  textTransform: "none",
                  fontSize: { xs: 15, sm: 18 },
                }}
                color="secondary"
                component="a"
                target="_blank"
                href="mailto:info@rgbjute.com"
              >
                info@rgbjute.com
              </Button>
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell
              sx={{
                borderRight: "1px solid rgba(81, 81, 81, 1)",
                fontSize: { xs: 15, sm: 18 },
              }}
              component="th"
              scope="row"
            >
              Year Of Establishment
            </TableCell>
            <TableCell align="left" sx={{ fontSize: { xs: 15, sm: 18 } }}>
              2018
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell
              sx={{
                borderRight: "1px solid rgba(81, 81, 81, 1)",
                fontSize: { xs: 15, sm: 18 },
              }}
              component="th"
              scope="row"
            >
              Commercial Production
            </TableCell>
            <TableCell align="left" sx={{ fontSize: { xs: 15, sm: 18 } }}>
              2019
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell
              sx={{
                borderRight: "1px solid rgba(81, 81, 81, 1)",
                fontSize: { xs: 15, sm: 18 },
              }}
              component="th"
              scope="row"
            >
              International Markets
            </TableCell>
            <TableCell align="left" sx={{ fontSize: { xs: 15, sm: 18 } }}>
              USA, Canada, Germany, Denmark, Netherlands, Norway.
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell
              sx={{
                borderRight: "1px solid rgba(81, 81, 81, 1)",
                fontSize: { xs: 15, sm: 18 },
              }}
              component="th"
              scope="row"
            >
              Product Line
            </TableCell>
            <TableCell align="left" sx={{ fontSize: { xs: 15, sm: 18 } }}>
              <li>
                Home Décor - Storage Baskets, Rugs, Door mats, Cushion, Sitting
                Pouf
              </li>
              <li>Garden décor - Plant basket, Plant hanger, Clay Planter</li>
              <li>Tablewear - Fruit basket, Placemats, Coaster</li>
              <li>Kids - Changing basket for babies</li>
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell
              sx={{
                borderRight: "1px solid rgba(81, 81, 81, 1)",
                fontSize: { xs: 15, sm: 18 },
              }}
              component="th"
              scope="row"
            >
              Raw Materials
            </TableCell>
            <TableCell align="left" sx={{ fontSize: { xs: 15, sm: 18 } }}>
              Seagrass, Jute, Date leaf, Rattan, Clay, Wool
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell
              sx={{
                borderRight: "1px solid rgba(81, 81, 81, 1)",
                fontSize: { xs: 15, sm: 18 },
              }}
              component="th"
              scope="row"
            >
              Sourcing
            </TableCell>
            <TableCell align="left" sx={{ fontSize: { xs: 15, sm: 18 } }}>
              Locally sourced and all are 100% natural fibers.
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell
              sx={{
                borderRight: "1px solid rgba(81, 81, 81, 1)",
                fontSize: { xs: 15, sm: 18 },
              }}
              component="th"
              scope="row"
            >
              HS Code
            </TableCell>
            <TableCell align="left" sx={{ fontSize: { xs: 15, sm: 18 } }}>
              46029000
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell
              sx={{
                borderRight: "1px solid rgba(81, 81, 81, 1)",
                fontSize: { xs: 15, sm: 18 },
              }}
              component="th"
              scope="row"
            >
              Production Capacity
            </TableCell>
            <TableCell align="left" sx={{ fontSize: { xs: 15, sm: 18 } }}>
              5x 40’ HC container per month
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell
              sx={{
                borderRight: "1px solid rgba(81, 81, 81, 1)",
                fontSize: { xs: 15, sm: 18 },
              }}
              component="th"
              scope="row"
            >
              Lead Time
            </TableCell>
            <TableCell align="left" sx={{ fontSize: { xs: 15, sm: 18 } }}>
              60 - 75 Days
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell
              sx={{
                borderRight: "1px solid rgba(81, 81, 81, 1)",
                fontSize: { xs: 15, sm: 18 },
              }}
              component="th"
              scope="row"
            >
              Bank Details
            </TableCell>
            <TableCell align="left" sx={{ fontSize: { xs: 15, sm: 18 } }}>
              Bank Name: Standard Bank Ltd
              {/* <br />
              Branch: Topkhana Road
              <br />
              A/C Name: M/S RGB Jute <br />
              A/C No: 00533012344 <br />
              Routing No: 210276732 <br />
              Swift Code: SDBLBDDH105 */}
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell
              sx={{
                borderRight: "1px solid rgba(81, 81, 81, 1)",
                fontSize: { xs: 15, sm: 18 },
              }}
              component="th"
              scope="row"
            >
              Nature of Business
            </TableCell>
            <TableCell align="left" sx={{ fontSize: { xs: 15, sm: 18 } }}>
              <li>Manufacturer</li>
              <li>Supplier</li>
              <li>Exporter</li>
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell
              sx={{
                borderRight: "1px solid rgba(81, 81, 81, 1)",
                fontSize: { xs: 15, sm: 18 },
              }}
              component="th"
              scope="row"
            >
              Associated Organizations
            </TableCell>
            <TableCell align="left" sx={{ fontSize: { xs: 15, sm: 18 } }}>
              <li>
                National Association of Small & Cottage Industries Bangladesh
                (NASCIB)
              </li>
              <li>Jute Diversification & Promotion Center (JDPC)</li>
              <li>
                Bangladesh Handicrafts Manufacturer & Exporters Association
                (BANGLACRAFT)
              </li>
              <li>Export Promotion Bureau</li>
            </TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell
              sx={{
                borderRight: "1px solid rgba(81, 81, 81, 1)",
                fontSize: { xs: 15, sm: 18 },
              }}
              component="th"
              scope="row"
            >
              Business Licenses
            </TableCell>
            <TableCell align="left" sx={{ fontSize: { xs: 15, sm: 18 } }}>
              <li>Certificate of Registration of Firm: P-44278/2019</li>
              <li>Trade License: TRAD/DSCC/366477/2019</li>
              <li>Tax Identification Number: 458218431464</li>
              <li>Vat Registration Certificate: 003407144-0202</li>
              <li>Export Registration Number: 260326210878420</li>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
