import Box from '@mui/material/Box'
import Cards from './Cards/Cards'
import Hero from './Hero'

const Products = ({ name }) => {
  return (
    <Box>
      <Hero name={name} />

      <Cards name={name} />
    </Box>
  )
}

export default Products
