import { useForm } from '@formspree/react'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

const Form = () => {
  const [state, handleSubmit] = useForm('myyozard')
  const isSmall = useMediaQuery('(max-width:600px)')
  if (state.succeeded) {
    return (
      <Box sx={{ bgcolor: '#F3F3F3', py: 5 }}>
        <Container
          maxWidth="sm"
          sx={{
            my: 10,

            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <MarkEmailReadIcon
            sx={{ fontSize: 50, color: 'primary.main', mb: 2 }}
          />
          <Typography variant={isSmall ? 'h6' : 'h5'} align="center">
            Thank you for your query. We shall get back to you soon. Have a nice
            day!
          </Typography>
        </Container>
      </Box>
    )
  }
  return (
    <Box sx={{ bgcolor: '#F3F3F3', py: 5 }}>
      <Container
        maxWidth="sm"
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <Typography align="center" variant="h3" gutterBottom>
          Leave us your info
        </Typography>
        <Typography align="center" variant="h5" color="gray">
          and we will get back to you.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 3,
            mb: 5,
          }}
        >
          <Box
            sx={{
              bgcolor: 'secondary.main',
              height: 2,
              width: { xs: '80%', sm: '20%' },
              my: 1,
            }}
          />
        </Box>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item xs={12}>
            <TextField
              InputProps={{
                sx: {
                  borderRadius: 0,
                  bgcolor: '#FFF',
                  '&:hover': {
                    bgcolor: '#FFF',
                  },
                  '&:focus-within': {
                    bgcolor: '#FFF',
                  },
                },
              }}
              fullWidth
              id="name"
              name="name"
              label="Your Name"
              variant="filled"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              InputProps={{
                sx: {
                  borderRadius: 0,
                  bgcolor: '#FFF',
                  '&:hover': {
                    bgcolor: '#FFF',
                  },
                  '&:focus-within': {
                    bgcolor: '#FFF',
                  },
                },
              }}
              fullWidth
              id="email"
              type="email"
              name="email"
              label="Email"
              variant="filled"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              InputProps={{
                sx: {
                  borderRadius: 0,
                  bgcolor: '#FFF',
                  '&:hover': {
                    bgcolor: '#FFF',
                  },
                  '&:focus-within': {
                    bgcolor: '#FFF',
                  },
                },
              }}
              fullWidth
              variant="filled"
              multiline
              rows={4}
              label="Message"
              id="message"
              name="message"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={state.submitting}
              type="submit"
              fullWidth
              variant="contained"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Form
