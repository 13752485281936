// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination } from 'swiper'

import Box from '@mui/material/Box'
import useAirtableGetFiltered from 'hooks/useAirtableGetFiltered'
import SingleCard from './SingleCard'

// install Swiper modules
SwiperCore.use([Pagination, Autoplay])

export default function Carousel({ id }) {
  const { data } = useAirtableGetFiltered(id)

  if (data.length === 0) return <Box>Loading...</Box>
  return (
    <Swiper
      // slidesPerView={1}
      spaceBetween={10}
      autoplay={{ delay: 2000, disableOnInteraction: false }}
      speed={1200}
      loop
      pagination={{
        clickable: true,
      }}
      breakpoints={{
        640: {
          slidesPerView: 1,
          //   spaceBetween: 5,
        },
        768: {
          slidesPerView: 2,
          //   spaceBetween: 5,
        },
        1024: {
          slidesPerView: 4,
          //   spaceBetween: 5,
        },
      }}
      // className='mySwiper'
    >
      {data.map((item) => (
        <SwiperSlide key={item.id}>
          <SingleCard item={item} />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
