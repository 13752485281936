import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'

const Quote = () => {
  return (
    <Box sx={{ py: { xs: 5, sm: 10 }, px: { xs: 2, sm: 8 } }}>
      <Grid container spacing={0} alignItems='stretch'>
        <Grid item xs={12} sm={3}>
          <Typography align='left' variant='h5' sx={{ fontWeight: 'bold' }}>
            Our Story
          </Typography>
          <Divider
            sx={{
              my: 2,
              height: '2px',
              width: '100px',
              backgroundColor: 'secondary.main',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <Typography
            // sx={{ ml: { xs: 0, sm: 5 } }}
            align='justify'
            variant='subtitle1'
          >
            Throughout the student life I thought about economic independency of
            woman and my mother was my mentor on this process. I realized the
            importance of this fact and it can change the society in a massive
            manner. Being an engineering student I always had the urge about
            practical passion and dreams. To fulfill this passion and experience
            the real situation, I started to volunteer in several non-profit
            organizations in my student life. By this time those moments put a
            mark on my thoughts, guided me positively. Then I started to
            volunteer with such organizations that specifically work with
            deprived and underdeveloped women community of the society. I went
            to several rural villages to experience it by myself. Spending a
            lots of time with them and saw their misery it came to my
            realization that temporary financial help or mere donation of food
            or clothes won’t change their fate and the situation they're in.
            They need a permanent solution with what they can be financially
            independent by themselves utilizing their talent and hard work.
            People of our country are traditionally skillful for making handmade
            goods using locally produced raw materials. Keeping that in mind I
            determined to establish a startup of which leading the women
            artisans was my top priority.
            <br />
            <br />
            First I focused on detailing, trained myself and gather proper
            information then I formed my own team. Our team is also well trained
            and focused in this field. It was then a huge step for me. I went
            villages to villages, find those needy local people who have
            potentials and mostly have determination to turnaround from their
            poor economic position. With few enthusiast and hard working woman I
            with my partner and co-owner have started our Very own start up
            business model in 2018. When we start our business first one year
            was basically a test of our patience. They have contributed in huge
            context with us from the beginning. Me and my marketing team
            continuously tried to make contact with different companies to get
            their attention and showed finely made handcrafted goods of our
            artisans. We didn’t give up and within this process an American NGO
            replied back to us. Their vision matches with ours and they came
            forward to support us. They trust us and gave us our first ever
            order, which brought a huge confidence and smile on our artisans
            face that finally their hard work is going to be recognized. They
            reviewed positively after receiving the shipment on time and very
            satisfied with the quality. Our gratitude will always be with them.
            Eventually we learn as a team and since then we worked with the
            companies from others countries in Germany, Denmark, and Netherland.
            Mostly we're expanding our business to European Market. But our
            vision is bigger. We want to showcase our crafts n goods entirely
            made by our woman artisans to the global market. we want to
            introduce eco-friendly biodegradable and recyclable products which
            will also be beneficial for the environmental movement.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Quote
