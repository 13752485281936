import Box from "@mui/material/Box"
import useMediaQuery from "@mui/material/useMediaQuery"
// import Article from "components/Article"
import Boxes from "components/Boxes"
import Facts from "components/Facts"
import Footer from "components/Footer"
import Hero from "components/Hero/Hero"
import Hero2 from "components/Hero/Hero2"
// import NewWelcome from "components/NewWelcome"
import Welcome from "components/Welcome/Welcome"
import MouseScroll from "utils/MouseScroll"

const Home = () => {
  const isSmall = useMediaQuery("(max-width:600px)")
  return (
    <Box>
      {isSmall ? <Hero /> : <Hero2 />}
      {/* <NewWelcome /> */}
      <Welcome />
      <MouseScroll />
      <Boxes />
      {/* <Article /> */}
      <Facts />
      {/* <Instagram /> */}
      <Footer />
    </Box>
  )
}

export default Home
