import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

const BottomText = () => {
  return (
    <Box p={5}>
      <Typography
        sx={{
          color: '#fff',
          fontWeight: 'bold',
          typography: { xs: 'h4', sm: 'h3' },
        }}
        align="left"
        variant="h3"
      >
        Integrity & Empowerment
      </Typography>
      <Divider
        sx={{
          my: 2,
          height: '2px',
          width: '150px',
          backgroundColor: 'secondary.main',
        }}
      />
      <Typography
        sx={{ color: '#fff', textAlign: 'justify' }}
        align="left"
        variant="body1"
      >
        Our company is dedicated to empowering women from both urban and rural
        communities by providing them with the opportunity to participate in
        diversified activities for financial stability. Through our sea grass
        and jute-based handicrafts production, we aim to engage rural women in a
        comprehensive development process by providing them with knowledge,
        training, and skills, as well as creating opportunities for them to grow
        and thrive. Our ultimate goal is to empower and inspire our women
        artisan to become financially independent.
      </Typography>

      <Box my={5} />
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={6}>
          <Typography
            sx={{ color: 'secondary.main' }}
            align="left"
            variant="h3"
          >
            10+
          </Typography>
          <Typography sx={{ color: '#fff' }} align="left" variant="h5">
            Producers group
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            sx={{ color: 'secondary.main' }}
            align="left"
            variant="h3"
          >
            7+
          </Typography>
          <Typography sx={{ color: '#fff' }} align="left" variant="h5">
            Country coverage
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            sx={{ color: 'secondary.main' }}
            align="left"
            variant="h3"
          >
            500+
          </Typography>
          <Typography sx={{ color: '#fff' }} align="left" variant="h5">
            Women artisans
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default BottomText
