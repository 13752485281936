import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'

import Typography from '@mui/material/Typography'
import Loading from 'components/Loading'
import useAirtableGet from 'hooks/useAirtableGet'
import { useState } from 'react'
import Carousel from './Carousel'
import Magnify from './Magnify'
import Right from './Right'

const SingleProducts = ({ id }) => {
  const { data } = useAirtableGet('Products')
  const [hover, setHover] = useState(false)
  if (data.length === 0) return <Loading />

  const filteredData = data.filter((item) => item.id === id)
  console.log(filteredData)

  return (
    <Box>
      <Box
        sx={{
          bgcolor: 'primary.main',
          minHeight: '100vh',
          pt: 8,
          pb: 5,
          px: { xs: 2, sm: 0 },
        }}
      >
        <Grid
          container
          alignItems="stretch"
          justifyContent="center"
          spacing={5}
        >
          <Grid item xs={12} sm={12} md={5}>
            <Paper
              sx={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Magnify data={filteredData[0]} setHover={setHover} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <Paper sx={{ height: '100%', p: 4 }}>
              <Right data={filteredData} hover={hover} />
            </Paper>
          </Grid>
        </Grid>
      </Box>

      <Box my={5} mx={5}>
        <Typography variant="h4" align="center">
          Related Products
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: 3,
          }}
        >
          <Box
            sx={{
              bgcolor: 'secondary.main',
              height: 2,
              width: { xs: '80%', sm: '25%' },
              my: 1,
            }}
          />
        </Box>
        <Carousel id={filteredData[0]?.fields.category_name[0]} />
      </Box>
    </Box>
  )
}

export default SingleProducts
