// Import Swiper React components

// Import Swiper styles
// import "swiper/css"
// import "swiper/css/navigation"
// import "swiper/css/pagination"
import amfori from "img/amfori.svg"

import "./styles.css"

// import Swiper core and required modules
// import SwiperCore, { Autoplay, Parallax } from "swiper"

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import hero2 from "img/bg-3-d.webp"
import { Link } from "react-router-dom"

// install Swiper modules
// SwiperCore.use([Autoplay, Parallax])

export default function App() {
  return (
    <Box
      className="height-100"
      sx={{
        "& img": {
          display: "block",
          position: "absolute",
          top: 20,
          right: 0,
          height: "auto",
          width: 400,
          p: 3,
          zIndex: 1,
        },
      }}
    >
      {/* <Swiper
        autoplay={{ delay: 2000, disableOnInteraction: false }}
        loop
        speed={2000}
        parallax={true}
        className="mySwiper"
      >
        <SwiperSlide>
          <Container
            className="height-100"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div className="content">
              <Typography
                sx={{ color: '#fff' }}
                variant="h4"
                fontWeight="bold"
                gutterBottom
                data-swiper-parallax={-400}
              >
                Traditional Craftsmanship
              </Typography>
              <Typography
                sx={{ color: 'white' }}
                variant={'h1'}
                fontWeight="bold"
                gutterBottom
                data-swiper-parallax={-200}
              >
                Supporting local artisans
              </Typography>
              <Button
                component={Link}
                to="/contact"
                sx={{ px: 8, color: '#fff' }}
                variant="contained"
                color="secondary"
              >
                Contact Us
              </Button>
            </div>
          </Container>
          <div
            data-swiper-parallax={'70%'}
            className="slider-bg"
            style={{
              filter: 'saturate(0.8)',
              backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4)), 
              url(${herobgD})`,
              backgroundSize: 'cover',
              backgroundPosition: 'bottom center',
              backgroundRepeat: 'no-repeat',
            }}
          />
        </SwiperSlide>
        <SwiperSlide> */}

      <Container
        className="height-100"
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          position: "relative",
        }}
      >
        <div className="content">
          <Typography
            sx={{ color: "#fff" }}
            variant="h4"
            fontWeight="bold"
            gutterBottom
            data-swiper-parallax={-400}
          >
            Fully Export Oriented
          </Typography>
          <Typography
            sx={{ color: "white" }}
            variant={"h2"}
            fontWeight="bold"
            gutterBottom
            data-swiper-parallax={-200}
          >
            Design meets <br /> perfection
          </Typography>
          <Button
            component={Link}
            to="/category"
            sx={{ px: 8, color: "#fff" }}
            variant="contained"
            color="secondary"
          >
            See Products
          </Button>
        </div>
      </Container>
      <img src={amfori} alt="amfori" />
      <div
        data-swiper-parallax={"70%"}
        className="slider-bg"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4)), 
              url(${hero2})`,
        }}
      />
      {/* </SwiperSlide>
      </Swiper> */}
    </Box>
  )
}
