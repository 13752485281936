import Box from '@mui/material/Box'
import Bottom from './Bottom'
import Top from './Top'

const Boxes = () => {
  return (
    <Box>
      <Top />
      <Bottom />
    </Box>
  )
}

export default Boxes
