import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import top from 'img/new/boxtop.webp'
import TopText from './TopText'

const Top = () => {
  return (
    <Box>
      <Grid container spacing={0}>
        <Grid
          container
          alignItems='center'
          sx={{
            bgcolor: '#212121',
          }}
          item
          sm={12}
          lg={6}
        >
          <TopText />
        </Grid>
        <Grid item sm={12} lg={6}>
          <img width='100%' height='100%' src={top} alt='top' />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Top
