import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { useState } from 'react'
import MapModal from './MapModal'

const Location = () => {
  const [open, setOpen] = useState(false)
  return (
    <Box
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LocationOnIcon sx={{ fontSize: 45, color: 'primary.main', mb: 4 }} />
      <Typography
        variant="h5"
        fontWeight="bold"
        color="primary.main"
        sx={{ mb: 3 }}
      >
        Location
      </Typography>

      <Box
        sx={{
          bgcolor: 'secondary.main',
          height: 2,
          width: { xs: '50%', sm: '40%' },
          my: 1,
        }}
      />

      {/* <Typography
        variant='subtitle1'
        color='gray'
        align='center'
        sx={{ mb: 3 }}
      >
        A wonderful serenity has taken possession of my entire soul, like these
        sweet mornings.
      </Typography> */}
      <MapModal open={open} setOpen={setOpen} />
    </Box>
  )
}

export default Location
