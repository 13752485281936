import { Container, Grid } from '@mui/material'
import { Box } from '@mui/system'
import Loading from 'components/Loading'
import useAirtableGetFiltered from 'hooks/useAirtableGetFiltered'
import SingleCard from './SingleCard'

// import Animate from '../Animate'

const Cards = ({ name }) => {
  const { data } = useAirtableGetFiltered(name)
  if (data.length === 0) return <Loading />

  console.log(data)

  return (
    <Container maxWidth="xl">
      <Box py={5}>
        <Grid container spacing={5}>
          {data.map((item) => (
            <Grid key={item.id} item xs={12} sm={6} md={3}>
              {/* <Animate anim='zoom-in-up'> */}
              <SingleCard item={item} />
              {/* </Animate> */}
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  )
}

export default Cards
