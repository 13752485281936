import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const StoryRight = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        px: { xs: 0, sm: 5 },
        bgcolor: '#F2F2F2',
      }}
    >
      <Container>
        <Typography
          align='left'
          sx={{ fontWeight: 'bold', mb: 3 }}
          variant='h4'
        >
          About RGB Jute
        </Typography>
        <Typography variant='body1' sx={{ textAlign: 'justify' }}>
          RGB JUTE is the manufacturer &amp; exporter of fully hand woven,
          customized, standard varieties of locally sourced sustainable
          handicrafts &amp; basketry items made out of sea grass - (hogla &amp;
          kaisa), jute, date and palm leaf, cane, bamboo etc. RGB Jute started
          its journey in 2019 with a vision to assist impoverished and
          disadvantages rural artisan families to attain self –sufficiency and
          to protect and introduce traditional craftsmanship to the modern
          world. We promote eco- friendly and sustainable products through
          enhancing the skills of rural artisans in Bangladesh. RGB Jute
          exclusively export attractive and stunning high finishes sea grass
          goods that can only found in very few areas in Bangladesh. Our trained
          and skilled artisans and manufacturing team are basically consisting
          of deprived, unemployed woman who desire to be financially stable by
          their own capability. Already our respective customers are highly
          satisfied with our products. However, over fifty-five families are
          being benefited by working with us. Deprived, unemployed and woman
          from rural areas are our foremost priority in terms of fulfilling our
          motive. We work with them under fair wages and safety. Currently we
          are exporting in the countries like Canada, USA, Germany, Denmark
          &amp; Netherlands.
        </Typography>
        <Box my={3} />
        <Typography variant='body1' sx={{ textAlign: 'justify' }}>
          Our motive is to introduce our natural, sustainable and ecofriendly
          material to the modern world. Our company boosts itself by offering
          fastest commercial production and very high standard of quality in the
          international market. RGB JUTE has been successfully able to meet the
          challenges of ensuring standard quality norms and optimized business
          of infrastructure development of sustainable handicrafts. In the near
          future the company will emerge as an employment source of
          underdeveloped respondent artisan by expanding our productivity,
          quality and satisfaction that will meet our customer&#39;s
          requirements and our countries unemployment problem.
        </Typography>
      </Container>
    </Box>
  )
}

export default StoryRight
