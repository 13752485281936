import { Box } from '@mui/material'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import { default as Typography } from '@mui/material/Typography'

const research = [
  'Developing new techniques for faster and more efficient production',
  'Minimizing the environmental impact of our operations',
  'Developing high-quality, environmentally friendly products',
  'Collaborating with buyers to develop custom products',
]

const ResearchDevelopment = () => {
  return (
    <Container sx={{ py: 10 }}>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} sm={4}>
          <Typography align="left" variant="h5" sx={{ fontWeight: 'bold' }}>
            Research and Development
          </Typography>
          <Divider
            sx={{
              my: 2,
              height: '2px',
              width: '100px',
              backgroundColor: 'secondary.main',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography
            // sx={{ ml: { xs: 0, sm: 5 } }}
            align="justify"
            variant="subtitle1"
          >
            Our dedicated Research and Development (R&D) team is focused on
            exploring new techniques for faster and more efficient production,
            as well as minimizing the environmental impact of our operations.
            They work closely with our production team to develop new products
            that are tailored to meet the specific requirements of our buyers.
            We are always open to new ideas and suggestions, and we welcome
            collaboration with our buyers to develop custom products that meet
            their unique needs. In summary, our R&D efforts are focused on:
          </Typography>
          {research.map((res, index) => (
            <Box
              component="ul"
              sx={{
                my: 2,
                textAlign: 'justify',

                '& p': {
                  fontWeight: 'bold',
                  display: 'inline',
                },
              }}
            >
              <li key={index}>{res}</li>
            </Box>
          ))}
          <Typography
            // sx={{ ml: { xs: 0, sm: 5 } }}
            align="justify"
            variant="subtitle1"
          >
            At RGB Jute, we believe that innovation and sustainability go hand
            in hand. We are committed to pushing the boundaries of what is
            possible in the jute industry, and we look forward to continuing our
            R&D efforts to create a better, more sustainable future.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ResearchDevelopment
