import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ListItem from '@mui/material/ListItem'
import rgb from 'img/rgb.png'

const SocialLinks = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        component={Button}
        sx={{
          height: "100%",
          flexGrow: { xs: 1, md: 0 },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 2,
        }}
      >
        <img src={rgb} alt="rgb" width="150px" />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <ListItem button>
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            component="a"
            href="https://www.facebook.com/rgbjute/"
            target="blank"
            sx={{ borderRadius: 0, mr: 0.5 }}
          >
            <FacebookIcon />
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            component="a"
            href="https://www.instagram.com/rgbjute/"
            target="blank"
            sx={{ borderRadius: 0, mr: 0.5 }}
          >
            <InstagramIcon />
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            component="a"
            href="https://www.linkedin.com/company/rgbjute"
            target="blank"
            sx={{ borderRadius: 0, mr: 0.5 }}
          >
            <LinkedInIcon />
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            component="a"
            href="https://wa.me/+8801739700228"
            target="blank"
            sx={{ borderRadius: 0 }}
          >
            <WhatsAppIcon />
          </Button>
        </ListItem>
      </Box>
    </Box>
  )
}

export default SocialLinks
