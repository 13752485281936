import Box from '@mui/material/Box'
import Footer from 'components/Footer'
import SingleProducts from 'components/SingleProducts'
import { useParams } from 'react-router-dom'

const SingleProduct = () => {
  let { id } = useParams()
  return (
    <Box>
      <SingleProducts id={id} />

      <Footer />
    </Box>
  )
}

export default SingleProduct
