import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import SingleBox from './SingleBox'
import data from './data'

const Boxes = () => {
  return (
    <Box>
      <Grid container alignItems='stretch'>
        {data.map((item) => (
          <Grid sx={{ bgcolor: item.bg }} item xs={12} sm={6} md={3}>
            <SingleBox title={item.title} logo={item.logo} desc={item.desc} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default Boxes
