import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Slide from '@mui/material/Slide'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function ModalPolicy({ open, setOpen }) {
  const handleClose = () => {
    setOpen(false)
  }

  const descriptionElementRef = React.useRef(null)
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onClose={handleClose}
        scroll='paper'
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        TransitionComponent={Transition}
      >
        <DialogTitle
          sx={{ bgcolor: 'secondary.main' }}
          id='scroll-dialog-title'
        >
          Our Order Policy
        </DialogTitle>
        <DialogContent dividers={true}>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography variant='h6' fontWeight='bold' gutterBottom>
              Sample Policy:
            </Typography>

            <li>Sample lead time: Usually within 7-10 days.</li>
            <li>
              Shipping: Express via FEDEX, TNT, UPS, DHL, Post (As per
              requirement).
            </li>
          </Paper>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography variant='h6' fontWeight='bold' gutterBottom>
              Terms of Sales:
            </Typography>

            <li>Proforma invoice valid for 60 days.</li>
            <li>Production lead time depends on order quantity.</li>
            <li>
              5-10% allowance is acceptable since all our products are handmade.
            </li>
          </Paper>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography variant='h6' fontWeight='bold' gutterBottom>
              Shipping Details:
            </Typography>

            <li>By sea, air or combined transportation.</li>
            <li>
              Express shipping using FEDEX, TNT, UPS, DHL, Post (As per
              requirement).
            </li>
            <li>Bill of Lading document will be provided after delivery.</li>
            <li>
              Tracking Number will be provided immediately after delivery.
            </li>
          </Paper>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography variant='h6' fontWeight='bold' gutterBottom>
              Packaging:
            </Typography>

            <li>Packing: As per buyer requirement per carton.</li>
            <li>Carton: 5 ply or 7 ply corrugated carton.</li>
          </Paper>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography variant='h6' fontWeight='bold' gutterBottom>
              Container Loading:
            </Typography>

            <li>LCL or FCL on requirement of buyer.</li>
            <li>Container fumigation will be provided for product’s safety.</li>
          </Paper>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography variant='h6' fontWeight='bold' gutterBottom>
              Inspection & Testing:
            </Typography>

            <li>Testing and third part quality inspection is allowed.</li>
          </Paper>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography variant='h6' fontWeight='bold' gutterBottom>
              Trade Terms:
            </Typography>

            <li>FOB, CFR, CIF as per incoterms.</li>
          </Paper>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography variant='h6' fontWeight='bold' gutterBottom>
              Negotiable document:
            </Typography>

            <li>Commercial invoice.</li>
            <li>Packing list.</li>
            <li>Bill of lading document.</li>
            <li>Country of origin certificate or REX number.</li>
          </Paper>

          <Paper sx={{ p: 2 }}>
            <Typography variant='h6' fontWeight='bold' gutterBottom>
              Payment Terms:
            </Typography>

            <li>
              30-50% Advance(TT), Rest after providing Bill of Lading scan
              document.
            </li>
            <li>50% Advance with 100% Irrevocable L/C.</li>
            <li>
              All charges outside Bangladesh are from applicant’s account.
            </li>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='secondary'
            fullWidth
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
