import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import bottom from 'img/new/boxbottom.webp'
import BottomText from './BottomText'

const Bottom = () => {
  return (
    <Box>
      <Grid sx={{ flexWrap: 'wrap-reverse' }} container spacing={0}>
        <Grid item sm={12} lg={6}>
          <img width="100%" height="100%" src={bottom} alt="bottom" />
        </Grid>
        <Grid
          container
          alignItems="center"
          sx={{
            bgcolor: '#212121',
          }}
          item
          sm={12}
          lg={6}
        >
          <BottomText />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Bottom
