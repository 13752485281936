import About from "pages/About"
import Contact from "pages/Contact"
import Home from "pages/Home"
import Profile from "pages/Profile"
import Search from "pages/Search"
import { Route, Routes } from "react-router-dom"

// import OurWork from 'pages/OurWork'
import Category from "pages/Category"
import Product from "pages/Product"
import SingleProduct from "pages/SingleProduct"
import Sustainability from "pages/Sustainability"

const AppRoutes = () => {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="contact" element={<Contact />} />
      <Route path="profile" element={<Profile />} />
      <Route path="about" element={<About />} />
      <Route path="sustainability" element={<Sustainability />} />
      <Route path="category" element={<Category />} />
      <Route path="category/:id" element={<Product />} />
      <Route path="category/:id/:id" element={<SingleProduct />} />
      <Route path="search/:id" element={<SingleProduct />} />

      {/* <Route path='work' element={<OurWork />} /> */}
      <Route path="search" element={<Search />} />
    </Routes>
  )
}

export default AppRoutes
