import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import SearchProducts from "components/SearchProducts"
import useAirtableGet from "hooks/useAirtableGet"
import Loading from "Loading"
import { useState } from "react"
import TextTheme from "TextTheme"

const Search = () => {
  const { data } = useAirtableGet("Products")
  const [inputValue, setInputValue] = useState("")

  if (data.length === 0) return <Loading />

  const filteredData = data.filter((item) =>
    item.fields?.name?.toLowerCase().includes(inputValue)
  )

  return (
    <Box sx={{ minHeight: "100vh", width: "100%", bgcolor: "#212121" }}>
      <TextTheme>
        <Container sx={{ py: 12 }}>
          <TextField
            onChange={(e) => setInputValue(e.target.value)}
            color="secondary"
            autoFocus
            fullWidth
            label="Products"
          />

          <Box my={3} />

          {inputValue === "" ? (
            <Typography color="#fff" align="center" variant="h4" gutterBottom>
              Enter Something...?
            </Typography>
          ) : (
            <SearchProducts items={filteredData} />
          )}
        </Container>
      </TextTheme>
    </Box>
  )
}

export default Search
