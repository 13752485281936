import Box from "@mui/material/Box"
// import Article from "components/Article"
import Boxes from "components/Boxes"
import Facts from "components/Facts"
import Footer from "components/Footer"
import Amfori from "components/Sustainability/Amfori"
import Compliance from "components/Sustainability/Compliance"
import Hero from "components/Sustainability/Hero"

// import NewWelcome from "components/NewWelcome"

const Sustainability = () => {
  return (
    <Box>
      <Hero />
      {/* <NewWelcome /> */}
      <Compliance />
      <Amfori />
      <Footer />
    </Box>
  )
}

export default Sustainability
