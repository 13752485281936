import Box from '@mui/material/Box'
import ProfileIndex from 'components/Profile'

const Profile = () => {
  return (
    <Box>
      <ProfileIndex />
    </Box>
  )
}

export default Profile
