import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import facts from "./data"
import Single from "./Single"

const FactBox = () => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        p: { xs: 2, sm: 5 },
      }}
    >
      <Typography align="center" variant="h3" sx={{ mb: 2 }}>
        Some Facts
      </Typography>
      {/* <Container> */}
      <Grid container spacing={3} alignItems="stretch">
        {facts.map((e) => (
          <Grid key={e.id} item xs={12} sm={12} md={6} lg={4}>
            <Single title={e.title} description={e.description} />
          </Grid>
        ))}
      </Grid>
      {/* </Container> */}
    </Box>
  )
}

export default FactBox
