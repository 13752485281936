import { Hidden } from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Boxes from 'components/About/Boxes'
import FairBusiness from 'components/About/FairBusiness'
import GlobalReach from 'components/About/GlobalReach'
import Hero from 'components/About/Hero'
import Mission from 'components/About/Mission'
import Quote from 'components/About/Quote'
import ResearchDevelopment from 'components/About/ResearchDevelopment'
import Story from 'components/About/Story'
import ThreePics from 'components/About/ThreePics'
import ThreePicsDesktop from 'components/About/ThreePicsDesktop'
import Values from 'components/About/Values'
import Vision from 'components/About/Vision'
import Footer from 'components/Footer'

const About = () => {
  return (
    <Box>
      <Hero />
      <Boxes />
      <Story />
      <Quote />
      <Hidden smUp>
        <ThreePics />
      </Hidden>
      <Hidden smDown>
        <ThreePicsDesktop />
      </Hidden>
      <Mission />
      <Divider sx={{ borderBottomWidth: 2 }} />
      <Vision />
      <Divider sx={{ borderBottomWidth: 2 }} />
      <Values />
      <Divider sx={{ borderBottomWidth: 2 }} />
      <FairBusiness />
      <Divider sx={{ borderBottomWidth: 2 }} />
      <ResearchDevelopment />
      <Divider sx={{ borderBottomWidth: 2 }} />
      <GlobalReach />
      <Footer />
    </Box>
  )
}

export default About
