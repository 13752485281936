import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const BoxAnim = ({ img, text }) => {
  return (
    <Box
      sx={{
        height: '50vh',
        backgroundImage: `url(${img})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        '&:hover': {
          '& .overlay': {
            opacity: '100%',
          },
          '& .overlayW': {
            width: '60%',
          },
          '& .overlayH': {
            height: '60%',
          },
        },
      }}
    >
      <Box
        className='overlay'
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          opacity: 0,
          width: '100%',
          height: '100%',
          bgcolor: 'rgba(0,0,0,0.5)',
          transition: 'all 0.3s ease-in-out',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant='h3' color='#fff'>
          {text}
        </Typography>
      </Box>
      <Box
        className='overlayW'
        sx={{
          transition: 'all 0.3s ease-in-out',
          top: 35,
          left: 10,
          position: 'absolute',
          bgcolor: '#FFF',
          width: '0%',
          height: 2,
        }}
      />
      <Box
        className='overlayH'
        sx={{
          transition: 'all 0.3s ease-in-out',
          top: 10,
          left: 35,
          position: 'absolute',
          bgcolor: '#FFF',
          height: '0%',
          width: 2,
        }}
      />
      <Box
        className='overlayW'
        sx={{
          transition: 'all 0.3s ease-in-out',
          bottom: 35,
          right: 10,
          position: 'absolute',
          bgcolor: '#FFF',
          width: '0%',
          height: 2,
        }}
      />
      <Box
        className='overlayH'
        sx={{
          transition: 'all 0.3s ease-in-out',
          bottom: 10,
          right: 35,
          position: 'absolute',
          bgcolor: '#FFF',
          height: '0%',
          width: 2,
        }}
      />
    </Box>
  )
}

export default BoxAnim
