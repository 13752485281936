import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

const Values = () => {
  return (
    <Container sx={{ py: 10 }}>
      <Grid container spacing={3} alignItems='stretch'>
        <Grid item xs={12} sm={4}>
          <Typography align='left' variant='h5' sx={{ fontWeight: 'bold' }}>
            Values
          </Typography>
          <Divider
            sx={{
              my: 2,
              height: '2px',
              width: '100px',
              backgroundColor: 'secondary.main',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography
            // sx={{ ml: { xs: 0, sm: 5 } }}
            align='justify'
            variant='subtitle1'
          >
            <li>Fair wages and safe working environment.</li>

            <li>Woman empowerment.</li>
            <li>Quality control and Customer satisfaction.</li>
            <li>Research and development.</li>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Values
