import { Container, Grid } from '@mui/material'
import { Box } from '@mui/system'
import Loading from 'components/Loading'
import useAirtableGet from 'hooks/useAirtableGet'
import SingleCard from './SingleCard'

const Cards = () => {
  const { data } = useAirtableGet('Category')

  if (data.length === 0) return <Loading />

  console.log(data)
  return (
    <Container maxWidth="xl">
      <Box py={5}>
        <Grid container spacing={5}>
          {data.map((item) => (
            <Grid key={item.id} item xs={12} sm={6} md={4}>
              {/* <Animate anim='zoom-in-up'> */}
              <SingleCard item={item} />
              {/* </Animate> */}
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  )
}

export default Cards
