import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'

const RightTable = ({ data, hover }) => {
  const sizeList = data.size.split('\n')
  const filterList = sizeList.filter((item) => item !== '')
  console.log('filterList', filterList)

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Material:
            </TableCell>
            <TableCell align="right">{data.material}</TableCell>
          </TableRow>
          {filterList?.length === 0 ? (
            <TableRow>
              <TableCell component="th" scope="row">
                Size:
              </TableCell>
              <TableCell align="right">N/A</TableCell>
            </TableRow>
          ) : filterList?.length < 2 ? (
            <TableRow>
              <TableCell component="th" scope="row">
                Size:
              </TableCell>
              <TableCell align="right">{data?.size}</TableCell>
            </TableRow>
          ) : (
            <TableRow sx={{ display: hover ? 'none' : '' }}>
              <TableCell sx={{ p: 0 }} component="th" scope="row" colSpan={12}>
                <Accordion sx={{ boxShadow: 0 }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Size</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {filterList.map((sz) => (
                      <li key={sz}>{sz}</li>
                    ))}
                  </AccordionDetails>
                </Accordion>
              </TableCell>
            </TableRow>
          )}

          <TableRow>
            <TableCell component="th" scope="row">
              Color:
            </TableCell>
            <TableCell align="right">{data.color}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Production Period:
            </TableCell>
            <TableCell align="right">{data.production_time}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Sample Lead Time:
            </TableCell>
            <TableCell align="right">{data.sample_time}</TableCell>
          </TableRow>
          <TableRow sx={{ '& td, & th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              Container Loading:
            </TableCell>
            <TableCell align="right">{data.container_loading}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default RightTable
