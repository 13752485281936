import { Box, Container, Divider, Grid, Typography } from "@mui/material"
import amfori from "img/amfori.svg"

const Amfori = () => {
  return (
    <Box
      sx={{
        py: { xs: 2, sm: 5 },
      }}
    >
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            mb: 2,
          }}
        >
          <Typography variant="h4" align="center" fontWeight="bold">
            Social Compliance Certifications
          </Typography>

          <Divider
            sx={{
              my: 2,
              height: "2px",
              width: "200px",
              backgroundColor: "secondary.main",
            }}
          />
        </Box>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="h6"
              align="justify"
              sx={{
                mb: 2,
                "& span": {
                  fontWeight: "bold",
                },
              }}
            >
              <span>Amfori BSCI: </span>
              This certification ensures that we comply with the social
              standards set forth by the amfori BSCI Code of Conduct, which
              focuses on the improvement of working conditions across global
              supply chains.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              "& img": {
                width: "100%",
                height: "auto",
              },
            }}
          >
            <Typography
              variant="h6"
              align="justify"
              sx={{
                mb: 2,
                "& span": {
                  fontWeight: "bold",
                },
              }}
            >
              <span>Sedex 4 Pillar: </span>
              Our compliance with Sedex 4 Pillar standards affirms our
              commitment to ethical trade and sustainable business practices,
              ensuring that we meet the highest standards in labor, health and
              safety, the environment, and business ethics.
            </Typography>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            "& img": {
              width: { xs: "100%", sm: "50%" },
              height: "auto",
            },
          }}
        >
          <img src={amfori} alt="amfori" />
        </Box>
      </Container>
    </Box>
  )
}

export default Amfori
