import { useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import ReactImageMagnify from 'react-image-magnify'

const Magnify = ({ data, setHover }) => {
  const isSmall = useMediaQuery('(max-width:600px)')
  return (
    <Box>
      {isSmall ? (
        <Paper
          elevation={0}
          sx={{
            bgcolor: 'primary.main',
            overflow: 'hidden',
          }}
        >
          <ReactImageMagnify
            {...{
              smallImage: {
                alt: 'Product Image',
                isFluidWidth: true,
                src: data?.fields.image[0].url,
              },
              largeImage: {
                src: data?.fields.image[0].url,
                width: 900,
                height: 900,
              },

              enlargedImagePosition: 'over',
              // shouldUsePositiveSpaceLens: true,
              isHintEnabled: true,
              shouldHideHintAfterFirstActivation: false,
            }}
          />
        </Paper>
      ) : (
        <Paper
          elevation={0}
          sx={{
            bgcolor: 'primary.main',
            overflow: 'hidden',
            zIndex: 999999,
            '&:hover': {
              overflow: 'visible',
            },
          }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <ReactImageMagnify
            {...{
              smallImage: {
                alt: 'Product Image',
                isFluidWidth: true,
                src: data?.fields.image[0].url,
              },
              largeImage: {
                src: data?.fields.image[0].url,
                width: 900,
                height: 900,
              },
              enlargedImageContainerDimensions: {
                width: '110%',
                height: '100%',
              },
              fadeDurationInMs: 500,
              //   enlargedImagePosition: 'over',
              isHintEnabled: true,
              shouldHideHintAfterFirstActivation: false,
            }}
          />
        </Paper>
      )}
    </Box>
  )
}

export default Magnify
