import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import Container from '@mui/material/Container'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import { useState } from 'react'
import sendEmail from 'hooks/sendEmail'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function Modal({ open, setOpen }) {
  const handleClose = () => {
    setOpen(false)
  }

  const [email, setEmail] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [name, setName] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    sendEmail({ email, name, companyName })
    setOpen(false)
  }

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          elevation={1}
          sx={{
            position: 'relative',
            color: 'primary',
            backgroundColor: 'secondary.main',
          }}
        >
          <Toolbar>
            <Typography
              sx={{ ml: 2, flex: 1, color: 'primary.main' }}
              variant='h6'
              component='div'
            >
              Request a Catalog
            </Typography>
            <IconButton
              edge='start'
              color='inherit'
              sx={{ color: 'primary.main' }}
              onClick={handleClose}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Container sx={{ p: 3 }} component='form' onSubmit={handleSubmit}>
          <TextField
            // InputProps={{
            //   sx: { borderRadius: 0 },
            // }}
            fullWidth
            id='cname'
            type='cname'
            name='cname'
            label='Company Name'
            variant='outlined'
            onChange={(e) => setCompanyName(e.target.value)}
          />
          <br />
          <br />
          <TextField
            // InputProps={{
            //   sx: { borderRadius: 0 },
            // }}
            fullWidth
            id='name'
            type='name'
            name='name'
            label='Name'
            variant='outlined'
            onChange={(e) => setName(e.target.value)}
          />
          <br />
          <br />
          <TextField
            // InputProps={{
            //   sx: { borderRadius: 0 },
            // }}
            fullWidth
            id='email'
            type='email'
            name='email'
            label='Email'
            variant='outlined'
            required
            onChange={(e) => setEmail(e.target.value)}
          />
          <br />
          <br />
          <Button type='submit' fullWidth color='secondary' variant='contained'>
            Request
          </Button>
        </Container>
      </Dialog>
    </div>
  )
}
