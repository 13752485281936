import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import bg from 'img/page-title-background.webp'

const Hero = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), 
            url(${bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Container>
        <Typography align='center' color='#fff' variant='h3'>
          WE CONNECT WOMEN, CRAFT & COMMUNITIES
        </Typography>
        {/* <br />
        <Typography sx={{ textAlign: 'justify' }} color='#fff' variant='h6'>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto
          accusantium numquam odit laborum? Excepturi assumenda quis ducimus
          aliquid dolor, error placeat! Qui ad ipsam quasi odit non sapiente
          repellat eum ea laboriosam. Labore aperiam natus corporis quos, id
          reiciendis provident dolorem nam error debitis officiis voluptatum,
          numquam, minus est magnam!
        </Typography> */}
      </Container>
    </Box>
  )
}

export default Hero
