import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

const Vision = () => {
  return (
    <Container sx={{ py: 10 }}>
      <Grid container spacing={3} alignItems='stretch'>
        <Grid item xs={12} sm={4}>
          <Typography align='left' variant='h5' sx={{ fontWeight: 'bold' }}>
            Vision
          </Typography>
          <Divider
            sx={{
              my: 2,
              height: '2px',
              width: '100px',
              backgroundColor: 'secondary.main',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography
            // sx={{ ml: { xs: 0, sm: 5 } }}
            align='justify'
            variant='subtitle1'
          >
            Our vision is to reconcile economic growth by creating employment
            opportunities, environmental balance and social progress as well as
            ensuring that our woman artisans would have the same working
            opportunities and can lead a better life with economic independence.
            Our goal is to be the biggest exporter of sustainable and
            traditional handcrafted products in global market.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Vision
