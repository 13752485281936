import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import { Hidden } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import TextTheme from "TextTheme"
import Single from "./Single"
import SocialLinks from "./SocialLinks"
// import Insta from './Insta'

const Footer = () => {
  return (
    <Box
      sx={{
        color: "#fff",
        bgcolor: "#212121",
      }}
    >
      <Box sx={{ py: 1, px: 3 }}>
        <Container>
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
          >
            <Grid container justifyContent="center" item xs={12} sm={4}>
              <SocialLinks />
            </Grid>
            <Hidden smDown>
              <TextTheme>
                <Divider orientation="vertical" variant="middle" flexItem />
              </TextTheme>
            </Hidden>
            <Grid container justifyContent="center" item xs={12} sm={3}>
              <Single />
            </Grid>
            <Hidden smDown>
              <TextTheme>
                <Divider orientation="vertical" variant="middle" flexItem />
              </TextTheme>
            </Hidden>
            <Grid container justifyContent="center" item xs={12} sm={4}>
              <Single />
            </Grid>
            {/* <Grid container justifyContent='center' item xs={12} sm={4}>
              <Insta />
            </Grid> */}
          </Grid>
        </Container>
      </Box>
      <TextTheme>
        <Divider color="secondary">
          <Button
            component="a"
            href="#"
            variant="outlined"
            color="secondary"
            sx={{ borderRadius: 0, p: 2 }}
          >
            <ArrowUpwardIcon />
          </Button>
        </Divider>
      </TextTheme>
      <Typography align="center" sx={{ py: 3 }}>
        All rights reserved by RGB Jute | &copy; {new Date().getFullYear()}
      </Typography>
    </Box>
  )
}

export default Footer
