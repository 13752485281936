import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import RightTable from './Table'
// import Social from './Social'

const Right = ({ data, hover }) => {
  if (data.length === 0) return <h1>Hmmm</h1>
  const { fields } = data[0]
  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Typography align="left" variant="subtitle2" color="secondary">
        Product / {fields.category_name[0]} / {fields.name}
      </Typography>
      <Typography align="left" variant="h4">
        {fields.name}
      </Typography>
      <Typography align="left" variant="subtitle1" color="gray">
        Code: {fields.code}
      </Typography>
      <br />
      <RightTable data={fields} hover={hover} />
      {/* <br />
      <Social /> */}
    </Box>
  )
}

export default Right
