import Box from '@mui/material/Box'
import 'loading.css'
import { useEffect, useState } from 'react'

const Loading = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 4000)
  }, [])
  return (
    <Box
      className="height-100"
      sx={{
        zIndex: 5000,
        position: 'fixed',
        transition: 'all 0.6s ease-in-out',
        transform: loading ? 'translate(-0%,-0%)' : 'translate(-100%,-0%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        backgroundColor: '#212121',
      }}
    >
      <div className="container">
        <span className="text1">Welcome to</span>
        <span className="text2">RGB Jute</span>
      </div>
    </Box>
  )
}

export default Loading
